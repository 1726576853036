<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Employee</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Employee
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Employee</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_employee')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newEmployee',
              mode: 'create',
            })
          "
        >
          New Employee
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select v-model="params.size" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="(branch) in dataLists2.filter_branchs"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            class="float-right"
            v-model="params.searched"
            placeholder="Search..."
            @keyup="searchFilter"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Emp. ID</th>
              <th class="wd-15p">Employee Name</th>
              <th class="wd-10p">Designaton</th>
              <th class="wd-15p">Email</th>
              <th class="wd-10p">Contact No.</th>
              <th class="wd-10p">Advance</th>
              <th class="wd-10p">Salary Due</th>
              <th class="wd-5p">Status</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_employee_profile','edit_employee','delete_employee','create_salary_payroll'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && employeeLists.length > 0">
            <tr v-for="(employee, index) in employeeLists" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{ employee.employee_id }}</td>
              <td>{{ employee.name }}</td>
              <td>{{ employee.designation }}</td>
              <td>{{ employee.email }}</td>
              <td>{{ employee.mobile_no }}</td>
              <td>{{ parseDigitForSlip(employee.advance) }}</td>
              <td>{{ parseDigitForSlip(employee.closing_due) }}</td>
              <td>
               {{(employee.status==1)?'Active':'In-Active'}}
              </td>
              <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_employee_profile','edit_employee','delete_employee','create_salary_payroll'])">
                <a href="javascript:;" v-if="checkSinglePermission('create_salary_payroll')" class="mr-3" title="Add New Payroll">
                  <i
                    class="fas fa-receipt"
                    @click.prevent="newPayroll('employeeSalaryPayroll', employee.id)"
                  ></i>
                </a>
                <a href="javascript:;" v-if="checkSinglePermission('view_employee_profile')" class="mr-3" title="View">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view(employee.id)"
                  ></i>
                </a>
                <a href="javascript:;" v-if="checkSinglePermission('edit_employee')" class="mr-3" title="Edit">
                  <i
                    class="fa fa-edit"
                    @click="edit('editEmployee', employee.id)"
                  ></i>
                </a>
                <a href="javascript:;" v-if="checkSinglePermission('delete_employee')" title="Delete">
                  <i class="fa fa-trash" @click="drop(employee.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && employeeLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{error_message}}</td>
            </tr>
          </tbody>
        </table>  
         <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <EmployeeCreate @parent-event="getData()" />
        <SalaryPayroll @parent-event="getData()"/>
      </div>
    </div>
  </div>
</template>
<script>
import Services from "../Services/Services";
import Paginate from 'vuejs-paginate';
import EmployeeCreate from "./create";
import SalaryPayroll from "../SalaryPayroll/create";
import { mapGetters } from "vuex";
import _ from 'lodash';
export default {
  components: {
    EmployeeCreate,
    SalaryPayroll,
    Paginate,
  },
  data(){
    return{
      params:{
        size:10,
        branch:'',
        searched:'',
        offset:'',
      },
      loading:false,
      page:1,
      error:false,
      error_message:'',
      totalPageCount:0,
      pageSerialNo:1,
    }
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    view(id) {
      this.$router.push(`employee/profile/${id}`);
    },
    newPayroll(modal, id){
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "create" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$swal({
       title: 'Do you really want to delete employee ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true, 
        preConfirm:() => {
           return Services.deleteEmployee(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
              if(error.response.status == 500){
                this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    getData() {
      this.loading = true;
      Services.getEmployeeLists(this.params).then(res=>{
          this.$store.commit("employees/setEmployeeLists",res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
          // this.$store.commit("getData2", `api/employee/create`);
          Services.getEmployeeElements().then(res=>{
            this.$store.commit("setDataLists2",res.data.data);
          }).catch(err=>{
            console.log(err);
          })
      }).catch(err=>{ 
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.error = true;
          this.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
      });
    },
     searchFilter: _.debounce(function(){
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      Services.getEmployeeLists(this.params).then(res=>{
          this.$store.commit("employees/setEmployeeLists",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading=false;
      }).catch(err=>{
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          let error = err.response.data.error_message;
          this.setNotification(error);
      }).finally(()=>{
          this.loading = false;
      });
    },1000),
     setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "pagination",
      "eventMessage",
      "today"
    ]),
    ...mapGetters("employees",["employeeLists"])
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
</style>